<template>
  <div class="pt-fancybox-box-info pt-fancybox2-active fancyitem mb-4" style="padding: 25px 30px 52px 30px">
    <div class="pt-fancy-media" style="display: flex; flex-flow: row nowrap">
      <div class="col-3 px-0 pr-1">
        <i v-if="item.nombre == 'Alias de Dominio'" class="flaticon-007-monitor-screen"></i>
        <i
          v-else-if="item.nombre == 'IP Pública Adicional - IPv4 (UF)'"
          class="flaticon-011-cloud-computing"
        ></i>
        <i
          v-else-if="item.nombre == 'IP Pública Adicional - IPv4 - Anual (UF)'"
          class="flaticon-011-cloud-computing"
        ></i>

        <i
          v-else-if="item.nombre == 'Monitoreo puertos / Reinicio servidor (UF)'"
          class="flaticon-028-laptop-screen"
        ></i>
        <i v-else-if="item.nombre == 'Setup inicial - 3 horas (UF)'" class="flaticon-028-laptop-screen"></i>
        <i
          v-else-if="item.nombre == 'Administración / Monitoreo 24x7x365 (UF)'"
          class="flaticon-028-laptop-screen"
        ></i>
        <i
          v-else-if="item.nombre == 'Administración / Monitoreo 24x7x365 - Standard'"
          class="flaticon-028-laptop-screen"
        ></i>

        <i v-else-if="item.nombre == 'Comodo Essential SSL'" class="flaticon-023-paper"></i>
        <i v-else-if="item.nombre == 'Comodo Essential SSL Wildcard'" class="flaticon-023-paper"></i>
        <i v-else-if="item.nombre == 'Comodo Positive SSL multi-domain'" class="flaticon-023-paper"></i>

        <i v-else-if="item.nombre == 'cPanel/WHM VPS (UF)'" class="flaticon-030-web-browser"></i>
        <i
          v-else-if="item.nombre == 'cPanel/WHM VPS (Auto Scaling USD)'"
          class="flaticon-030-web-browser"
        ></i>

        <i v-else class="flat-icon-v flaticon-001-server"></i>
      </div>

      <div class="col-md-9 px-0 pt-pricebox-info price-card-new">
        <h2 class="price" style="text-align: right; font-size: 30px">
          <span
            v-if="item.moneda.nombre_corto == 'UF'"
            class="item-row-price"
            style="text-align: right"
            :data-node="true"
            >{{ changeSeparator((item.precio * form.cantidad).toFixed(2)) }}
          </span>
          <span
            v-else-if="item.moneda.nombre_corto == 'USD'"
            class="item-row-price"
            style="text-align: right"
            :data-node="true"
            >{{ changeSeparator((item.precio * form.cantidad).toFixed(2)) }}
          </span>

          <span v-else class="row-price" :data-node="true"
            ><span class="item-row-price">$</span>
            <span>{{ formatNumber(item.precio * form.cantidad) }}</span>
          </span>
        </h2>
        <p
          class="pt-price-duration"
          style="margin-bottom: 13px; margin-bottom: -10px; top: -5px; position: relative"
        >
          {{ item.moneda.nombre_corto }} / {{ item.periodicidad.nombre }}
        </p>
      </div>
    </div>
    <h4 class="pt-fancy-title-2">{{ item.nombre }}</h4>

    <!-- COLLAPSE  -->
    <span
    @click="readMore"
      data-toggle="collapse"
      :href="'#service_' + item.id"
      role="button"
      aria-expanded="true"
      :aria-controls="'#service_' + item.id"
    >
      <a v-if="!readStatus" style="cursor: pointer;">Leer más</a>
      <a v-else style="cursor: pointer;">Leer menos</a>
      
    </span>
    <div class="container cd-filter-block2 px-0">
      <div class="container-collapse" style="margin-bottom: 0px">
        <ul class="collapse fade-in" :id="'service_' + item.id" style="padding-left: 0">
          <div class="collapse-target" style="display: flex; flex-flow: row wrap">
            <div class="col-12 px-0">
              <div v-if="item.categoria.nombre == 'Microsoft Office 365' || item.categoria.nombre == 'Microsoft 365' " class="pt-fancybox-description containerMicrosoft" style="margin-bottom: 0px">
                <div class="containerMicrosoft_content">
                  <h5 class="containerMicrosoft_content_title"> {{ item.codigo == 'M365EB-A-SNT' ? "VERSIONES WEB OFFICE INCLUIDAS" : "APLICACIONES OFFICE INCLUIDAS" }} </h5>
                    <!-- si instalables es si y web no -->
                    <img v-if=" item.codigo == 'M365EE-A-NSB' || item.codigo == 'M365EE-M' || item.codigo == 'M365EP-A-NSA' || item.codigo == 'M365EP-M' || item.codigo == 'M365EB-A-NSB' || item.codigo == 'AM365-A' || item.codigo == 'AM365-A-NSO' "
                    src="images/microsoft/imagen_3.svg"
                    alt="microsoft office 365 paquete ihosting completo"
                    />
                    <!-- si web es si e instalable no -->
                    <img v-else-if=" item.codigo == 'M365EB-A-SNT' || item.codigo == 'M365EB-A-SNE' || item.codigo == 'M365EB-A-SNO' || item.codigo == 'M365EB-A-SNA' || item.codigo == 'M365EB-A-SNB' "
                     src="images/microsoft/imagen_5.svg"
                     alt="microsoft office 365 paquete ihosting basico"
                    />
                  <span class="containerMicrosoft_content_none" v-else>Ninguna</span>

                </div>

                <div class="containerMicrosoft_content">
                  <h5 class="containerMicrosoft_content_title"> SERVICIOS INCLUIDOS </h5>
                  <img v-if="item.codigo == 'M365EB-A-NSA' || item.codigo == 'M365EB-A-SNA' || item.codigo ==  'M365EP-A-NSA' "
                    src="images/microsoft/imagen_1.svg"
                    alt="microsoft paquete servicio completo"
                    />
                   <img v-else-if="item.codigo == 'M365EB-A-SNB' || item.codigo == 'M365EB-M' ||  item.codigo ==  'M365EE-A-NSB' || item.codigo ==  'M365EE-M' "
                    src="images/microsoft/imagen_0_left.svg"
                    alt="microsoft paquete servicio basico"
                    />
                    <img v-else-if="item.codigo == 'M365EB-A-NSE' || item.codigo == 'M365EB-A-SNE' || item.codigo == 'EOM365-A'  || item.codigo == 'EOM365-A-NNE' || item.codigo == 'EOM365-A-NNE'"
                    src="images/microsoft/imagen_4_left.svg"
                    alt="microsoft paquete servicio exchange"
                              />
                    <img v-else-if="item.codigo == 'M365EB-A-NSO' || item.codigo == 'M365EB-A-SNO' || item.codigo == 'M365AP-A-NSO' || item.codigo == 'AM365-A' || item.codigo == 'M365ODB01A' || item.codigo == 'AM365-A-NSO' "
                    src="images/microsoft/imagen_2_left.svg"
                     alt="microsoft paquete servicio onedrive"
                    />
                    <img v-else-if="item.codigo == 'M365EB-A-SNT' || item.codigo == 'M365EB-M' "
                    src="images/microsoft/tems_left.svg"
                    alt="microsoft paquete servicio teams"
                    />           
                </div>
              </div>
              
              <p class="pt-fancybox-description" style="margin-bottom: 0px">{{ item.descripcion }}</p>

            </div>
          </div>
        </ul>
      </div>
    </div>

    <div class="pt-btn-container">
      <div class="pt-button-block">
        <div v-show="type == 1" :data-node="true">
          <a
            @click="next_step(item)"
            class="btn btn-default-grad-purple-fill white-font blue-btn-custom"
            :data-node="true"
            >Configurar</a
          >
        </div>

        <div v-show="type != 1" :data-node="true">
          <configuration-dialog
            v-if="category.categoria.campos_completables.length > 1"
            :id="item.id"
            :campos="category.categoria.campos_completables"
            :service="item"
            :data-node="true"
            @show="showDialog"
            @hide="hideDialog"
          />
          <div v-else :data-node="true">
            <div style="display: flex; flex-flow: row nowrap" :data-node="true">
              <div
                v-if="item.cuantificable == 1"
                class="col-md-12 px-0"
                style="height: 40px"
                :data-node="true"
              >
                <ul
                  class="radio-group radios-filter cd-filter-content list ul-v-list cd-filter-block"
                  style="position: relative; top: 6px; padding-left: 0"
                  :data-node="true"
                >
                  <label class="vue-input" :data-node="true" style="margin-bottom: 0rem"
                    ><b :data-node="true">Cantidad</b></label
                  >
                  <div
                    style="display: flex; flex-flow: row nowrap; width: 45px; position: relative; top: -7px"
                    :data-node="true"
                  >
                    <input
                      v-model="form.cantidad"
                      onkeypress="if((event.charCode < 48 || event.charCode > 57) || this.value.length > 1) return false"
                      @keydown="checkNumber($event, form.cantidad)"
                      min="1"
                      max="99"
                      type="number"
                      name="cantidad"
                      id="cantidad_"
                      class="input_num"
                      :data-node="true"
                    />
                  </div>
                </ul>
              </div>
            </div>

            <div class="px-0 col-md-12" :class="{ 'mt-fix-v': item.cuantificable != 1 }" :data-node="true">
              <a
                @click="addToServiceAdicionales()"
                class="btn btn-default-grad-purple-fill white-font blue-btn-custom"
                :data-node="true"
                ><span v-if="item.cuantificable == 1" :data-node="true"> Agregar {{ form.cantidad }}</span
                ><span v-else :data-node="true">Agregar</span></a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { formatNumber, changeSeparator, resolucion, checkNumber } from "@/helpers";
export default {
  components: {
    "configuration-dialog": () =>
      import(/* webpackChunkName: "configuration-dialog" */ "./ConfigurationDialog"),
  },
  props: ["item", "type", "category", "subcategory", "change"],
  data: () => ({
    SelectOptions: [1, 2, 3],
    domain: null,
    form: {
      cantidad: 1,
    },
    showByIndex: null,
    dialog: false,
    readStatus: false
  }),

  watch: {
    change(id) {
      if (this.showByIndex != id) {
        this.showByIndex = null;
      }
    },
  },
  computed: {
    resolucion,
  },

  methods: {
    ...mapMutations(["addServiceToDetailAdicional"]),
    formatNumber,
    changeSeparator,
    checkNumber,

    next_step(item) {
      this.$emit("next", item);
    },
    addToServiceAdicionales() {
      // console.log("addToServiceAdicionales: this.item: ", this.item);
      this.$Progress.start();
      let ser = this.item;

      if (this.form.cantidad == 0) {
        this.$Progress.fail();
        this.$toast.error("Cantidad ingresada incorrecta.");
        return false;
      }

      ser["configuracion"] = this.form;
      ser["estado"] = 1;
      ser["nombre_"] = null;
      this.addServiceToDetailAdicional(JSON.parse(JSON.stringify(ser)));
      this.$toast.success("Servicio adicional agregado.");
      this.$Progress.finish();
    },
    showDialog() {
      this.dialog = true;
    },
    hideDialog() {
      setTimeout(() => {
        this.dialog = false;
      }, 300);
    },

    showOn(id, event) {
      this.showByIndex = id;
      this.$emit("showConfiguration", id);
    },
    showOff(id, event) {
      if (!event.target.dataset.node && !this.dialog) {
        this.showByIndex = false;
      }
    },
    readMore(){
      // console.log("read more", this.readStatus);
      this.readStatus = !this.readStatus;
    }
  },
};
</script>

<style scoped>
.pt-fancy-title-2 {
  margin-bottom: 5px;
  padding-bottom: 8px;
  font-size: 27px;
  border-bottom: 2px solid var(--grey-color);
}
.flat-icon-v {
  font-size: 60px !important;
}
.row-price {
  display: flex;
  flex-flow: row nowrap;
}

.item-row-price {
  margin-left: auto !important;
}

.pt-price-duration {
  float: right;
  margin-left: auto !important;
}

.input_num {
  -webkit-appearance: none !important;
  width: 100% !important;
  border: 0 !important;
  border-bottom: 1px solid #3696ce !important;
  font-family: inherit !important;
  padding: 0 !important;
  padding-left: 9px !important;
  height: 35px !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  background: none !important;
  border-radius: 0 !important;
  transition: all 0.15s ease !important;
}

/* Card if is microsoft */
.containerMicrosoft_content_title{
  font-size: 14px;
  font-weight: 600;
}
.containerMicrosoft_content_none{
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #3696ce !important;
  margin: 2px auto;
}

@media (max-width: 576px) {
  .wrap-blog .wrapper {
    padding: 0 !important;
  }
}
</style>
